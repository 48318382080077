<!--
* @description:
* @fileName tMap.vue
* @author hvv
* @date 2022/01/13 15:21:21
!-->
<template>
  <el-dialog v-model="dialogTableVisible" title="地址选择">
    <!-- <div id="mapContainer"></div> -->
    <iframe
      id="mapPage"
      width="600px"
      height="500px"
      frameborder="0"
      src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=SR2BZ-QH4C4-EHJUP-D7TOD-MKQAQ-WUB2A&referer=myapp"
    ></iframe>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取消</el-button>
        <el-button type="primary" @click="dialogTableVisible = false">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <!--  -->
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  export default defineComponent({
    name: 'tMap',
    emits: ['choose-place'],
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()
      const initMap = () => {
        window.addEventListener(
          'message',
          function (event) {
            // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
            var loc = event.data
            if (loc && loc.module == 'locationPicker') {
              //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
              console.log('location', loc)
              emit('choose-place', loc)
            }
          },
          false
        )
      }

      const state = reactive({
        dialogTableVisible: false,
      })

      const showDialog = () => {
        state.dialogTableVisible = true
      }
      onMounted(() => {
        initMap()
      })

      return {
        ...toRefs(state),
        showDialog,
        initMap,
      }
    },
  })
</script>
<style lang="scss" scoped>
  #mapContainer {
    width: 900px;
    height: 800px;
  }
</style>
